.delete-user-header{
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding-bottom: 1%;
    padding-top: 1px;
    margin-bottom: 5%;
    background-color: hsl(168deg 87% 35%);
    width: 100%;
    color: white;
    justify-content: left;
    display: flex;
    .iksiHeader{
        margin-right: 10px;
    }
}
