.edit-user-modal {
    z-index: 9;
    color: black;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.376);
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.edit-user-header {
        background-color: hsl(168deg 87% 35%);
        width: 100%;
        color: white;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
    .edit-user-content {
        border-radius: 12px;
        width: 500px;
        max-height: 25%;
        background-color: rgb(255, 255, 255);
        color: black;
        .xbtn{
            margin-right: 2% !important;
            cursor: pointer;
        }
        .edit-user-btn {
            background-color: hsl(168deg 87% 35%) !important;
            color: white;
            margin-left: 27%;
        
            &:hover {
                color: white;
            }
        }
    }
    .edit-user-body{
        .oldPassField{
            width: 78.5%;
            margin-bottom: 5px;
        }
        .newPassField{
            margin-right: 5px;
            border-top-right-radius: 0.375rem !important;
            border-bottom-right-radius: 0.375rem!important;
        }
        .generatePasswordBtn{

        }
    }
    .groupWidth{
        width: 95.5%;
    }

    