// timing
$base-duration: 250ms;

// Colors
$color1: rgba(40, 35, 38, 1);
$color2: rgba(61, 90, 108, 1);
$color3: rgba(114, 169, 143, 1);
$blueModus: #0ca788;

$success: #2ecc71;
$info: #3498db;
$warning: #f1c40f;
$danger: #e74c3c;

$white: #fff;
$black: #000;
$gray: #ecf0f1;
$whitesmoke: whitesmoke;
$text: #555;

// Breakpoints
$sm: 20rem;
$med: 48rem;
$lg: 64rem;

// sizes
$max-width: 1200px;
$spacer: 1rem;

// fonts / icons
$roboto: "Roboto", sans-serif;
$robotoCondensed: "Roboto Condensed", sans-serif;
$awesome: "Font Awesome 5 Free";
$awesomeWeight: 900;

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}
.LoaderContainer {
position: sticky;
top: 50%;
width: 100%;
  html {
    font-family: $roboto;
    font-size: 16px;
    font-smooth: auto;
    font-weight: 300;
    line-height: 1.5;
    color: #444;
  }

  body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    // background-image: url('https://images.pexels.com/photos/2261166/pexels-photo-2261166.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
    background-position: center;
    background-size: cover;
  }

  .loader {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: $blueModus;
    border-radius: 50%;
    &:before {
      content: "Loading";
      color: $white;
      font-family: $robotoCondensed;
      font-size: 0.875rem;
      text-transform: uppercase;
    }
    &:after {
      position: absolute;
      content: "";
      width: 100px;
      height: 100px;
      background-color: $blueModus;
      border-radius: 50%;
      opacity: 0.7;
      transform: scale(0.85);
      animation: loader 1s ease infinite;
      // z-index: -1;
    }
  }

  @keyframes loader {
    // 0% { transform: scale(0.5); opacity: 0.8; }
    85% {
      opacity: 0;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }

  .notLoading {
    padding: $spacer;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}
