
.text-center{
    margin-left: 12%;
}
.emriPytsorit{
    width: 100%;
}
.question{
    .Pytjet-Ne-Survey{

        width: 100%;
    }
    // background-color: firebrick;

}
.add-question-save{
    // margin-left: 70%;
    background-color:#0ca788 ;

}
.add-new-question-btn{
    background-color: #f8f9fa;
    color: black;
    // margin-right: 70%;
}
.borderi{
    box-shadow: rgba(12, 167, 136, 0.60) 0px 5px 0px 0px;
            border-radius: 12px;
            padding-left: 1%;
            padding-bottom: 1%;
            padding-top: 1%;
            padding-right: 1%;
            background-color: white;
            .form-control{
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                border-radius: 12px;
                padding-left: 2%;
                padding-bottom: 1%;
                padding-top: 1%;
                padding-right: 1%;
                background-color: white;
                resize: none;
            }
}