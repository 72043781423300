.Service-modal {
    color: black;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;

    .Service-header {
        padding-bottom: 1%;
        padding-top: 1px;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        margin-bottom: 5%;
        background-color: hsl(168deg 87% 35%);
        width: 100%;
        color: white;
        justify-content: left;
        display: flex;

    }
    .Service-content {
        border-radius: 12px;
        width: 40%;
        height: auto;
        background-color: rgb(255, 255, 255);
        color: black;
        z-index: 99;

        .xService-btn {
            margin-right: 2%;
            cursor: pointer;
        }

        .Service-btn {
            background-color: hsl(168deg 87% 35%) ;
            color: white;

            &:hover {
                color: white;
            }
        }
    }
}