.handleClose {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.376);
    // filter: blur(1rem);
    // -webkit-filter: blur(4px);
    border: unset;
    margin: auto;
}

.surveyName {
    margin-left: 4%;
    gap: 7.5%;
}

.ViewSurvey-header {
    text-align: center;
    position: fixed;
    top: 12%;
    z-index: 9;
    left: 5%;
    right: 5%;
    bottom: 81%;
    margin: 0 auto;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    flex-direction: row;
    background-color: hsl(168deg, 87%, 35%);

    .HeaderTekst {
        margin-top: 12px;
    }

    .iksiHeader {
        margin-top: 12px;
        margin-right: 10px;
    }
}

.ViewSurvey {
    margin: 0 auto;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    text-align: center;
    flex-direction: column;
    position: fixed;
    top: 19%;
    left: 5%;
    right: 5%;
    bottom: 10%;
    background-color: #f6f7f9;
    align-items: center;
    justify-content: center;
    z-index: 99;
    overflow-y: scroll;
    overflow-x: hidden;

    .emri-pytsorit {
        text-align: initial;
    }

    .fullModal {
        border-bottom-left-radius: 12px;
        min-height: 100%;
        max-height: 100%;
        background-color: #f6f7f9 !important;

        .doYouReallyWantToDelete {
            position: absolute;
            font-size: xxx-large;
            color: red;
            width: 94.5%;
            text-align: center;
        }

        .shadowDisable {
            .borderi {
                box-shadow: rgba(12, 167, 136, 0.6) 0px 5px 0px 0px;
                border-radius: 12px;
                padding-left: 1%;
                padding-bottom: 1%;
                padding-top: 1%;
                padding-right: 1%;
                background-color: #e9ecef;

                .Pytjet-Ne-Survey {
                    background-color: #e9ecef;
                }
            }
        }

        .shadowEnable {
            .borderi {
                box-shadow: rgba(12, 167, 136, 0.6) 0px 5px 0px 0px;
                border-radius: 12px;
                padding-left: 1%;
                padding-bottom: 1%;
                padding-top: 1%;
                padding-right: 1%;
                background-color: white;
            }
        }

        .buttonatEdit {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-top: 2%;

            .addNewBtn {
                margin-bottom: 2%;
            }

            .editBtnSv {
                background-color: #0ca788;
                border: none;
                margin-bottom: 2%;
            }
        }

        .gjuhet {
            font-weight: bold;
        }

        .form-control {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            border-radius: 12px;
            padding-left: 2%;
            padding-bottom: 1%;
            padding-top: 1%;
            padding-right: 1%;
            background-color: white;
            resize: none;
        }

        .fa-pen {
            color: #0ca788;
            margin-right: 15px;
            margin-left: 5px;
        }

        .fa-trash {
            color: red;
            margin-left: 5px;
        }
    }
}
.HrDashStyle {
    width: 329px;
    position: absolute;
}
.optionsButtons {
    justify-content: flex-end;
    margin-top: 20px;
    .EditOptionButton {
        margin-left: 8px;
        margin-right: 8px;
        position: absolute;
        display: contents;
    }
    .DeleteOptionButton {
        display: contents;
        position: absolute;
    }
    .OptionBTN-text{
        align-items: center;
    }
}
.optionbg{
    background-color: #e9ecef !important;
}
