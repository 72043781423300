.passwordChange-modal {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .passwordChange-content {
        z-index: 99999;
        width: 500px;
        border-radius: 15px;
        height: auto;
        background-color: rgb(255, 255, 255);
        color: black;
        
        .passwordChange-header {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            background-color: hsl(168deg 87% 35%);
            width: 100%;
            color: white;
            .x-btn {
                margin-right: 5%;
                cursor: pointer;
            }
        }
    }  
}