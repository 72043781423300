.widget-modal {
    color: black;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .widget-header {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding-bottom: 1%;
        padding-top: 1px;
        margin-bottom: 5%;
        background-color: hsl(168deg 87% 35%);
        width: 100%;
        color: white;
        justify-content: left;
        display: flex;
    }

    .widget-content {
        width: 500px;
        height: auto;
        animation: scale-up-center 500ms;
        background-color: rgb(255, 255, 255);
        color: black;
        border-radius: 12px;
        z-index: 999;

        .widget-btn {
            background-color: hsl(168deg 87% 35%);
            color: white;
            margin-left: 25%;

            &:hover {
                color: white;
            }
        }
        .iksiHeader {
            margin-right: 20px;
        }
    }
}