.edit-service-modal {
  
    color: black;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  
    .edit-service-content {
      z-index: 99 !important;
      border-radius: 12px;
        width: 45%;
        height: fit-content;
        background-color: rgb(255, 255, 255);
        color: black;
        z-index: 999;
        .edit-service-header {
          border-top-left-radius:12px ;
          border-top-right-radius:12px ;
          padding-bottom: 1%;
          padding-top: 1px;
          margin-bottom: 5%;
          background-color: hsl(168deg 87% 35%);
          width: 100%;
          color: white;
          justify-content: left;
          display: flex;
          .iksi{
            margin-right: 3%;
            cursor: pointer;
          }
        }
      
  
      .Service-btn {
        background-color: hsl(168deg 87% 35%);
        color: white;
        margin-left: 27%;
        font-weight: 700;
        font-size: large;
        margin: 0 auto;
        display: flex;
  
        &:hover {
          color: white;
          background-color: hsl(168deg 87% 35%);
        }
      }
  
      .xService-btn {
        margin-left: 60%;
        cursor: pointer;
      }
    }
  }
  