.surveyListing {
    .hoverView {
        &:hover {
            color: #0ca788;
            cursor: pointer;
        }
    }
    .hoverDelete {
        &:hover {
            color: red;
            cursor: pointer;
        }
    }
}
