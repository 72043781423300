
.main-items{
    padding-right:4% ;
    background-color: hsl(168deg 87% 35%) ;
}

.main-text{
    text-decoration: none;
    color: black;
}
.main-icon{
    max-height: 130px;
}
.card{
    border-radius: 50px;
}
.main-buttons {
    background-color: white;
    color: black;
    margin-top: 10px;
  
    &:hover {
      color: black;
      background-color: white;
    }
  }



//   .main-items {
//     // padding-right:4% ;
//     background-color: hsl(168deg 87% 35%);
//     align-items: center;
//     height: 65px;
//     justify-content: center;
//     .titulliMainPage {
//         align-items: flex-start;
//         display: contents;
//     }
//     .kontentiHeared {
//         justify-content: space-between;
//         width: 80%;
//         align-items: baseline;
//     }
// }
// .CardPositioning {
//     display: flex;
//     align-items: center;
//     height: 80vh;
//     .cardsMainPage {
//         display: flex;
//         justify-content: center;
//         margin: auto;
//         width: 100%;
//     }
// }

// .main-text {
//     text-decoration: none;
//     color: black;
// }
// .main-icon {
//     max-height: 130px;
// }
// .card {
//     border-radius: 50px;
// }
// .main-buttons {
//     background-color: white;
//     color: black;
//     margin-top: 10px;

//     &:hover {
//         color: black;
//         background-color: white;
//     }
// }
