
.signin-header{
    padding-bottom: 1%;
    padding-top:1px;
    margin-bottom: 5%;
    background-color:hsl(168deg 87% 35%);
    width: 100%;
    color: white;
    justify-content: left;
    display:flex ;
}
.signin-btn{
    background-color: hsl(168deg 87% 35%);
    color: white;
    &:hover{
        background-color: hsl(168deg 87% 35%);
        color: white;
    }
    &:focus{
        background-color: hsl(168deg 87% 35%);
    }
}
