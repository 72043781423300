.delete-user-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    // filter: blur(1rem);
    // -webkit-filter: blur(4px);
    border: unset;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .delete-user-header {
        background-color: hsl(168deg 87% 35%);
        width: 100%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        color: white;
         z-index: 99;
    }

    .delete-user-content {
        border-radius: 12px;
        width: 700px;
        // min-height: 400px;
        height: auto;
        animation: scale-up-center 500ms;
        background-color: rgb(255, 255, 255);
        color: black;
        z-index: 99;

        .tekstiNdelete {
            padding-bottom: 50px;
            width: 85%;
            text-align: center;
        }
        .delete-user-btn {
            .butonatDelete {
                min-width: 30%;
                font-weight: 700;
                font-size: x-large;
            }
            color: white;

            &:hover {
                color: white;
            }
        }

        .xwiget-btn {
            // margin-left: 43%;
            margin-right: 12px;
            cursor: pointer;
        }
    }
}
