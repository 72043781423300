.lista {
    margin-left: 10%;
    margin-right: 10%;
    background-color: rgb(216, 216, 216);
}
.listcon {
    margin-right: 10%;
    margin-left: 10%;
    .hoverViewEdit {
        cursor: pointer;
        &:hover {
            color: #0CA788;
        }
    }
}
.delete-location {
    &:hover {
        color: red;
    }
}

.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
    border-radius: 12px;
  }
  
  /* Style the buttons that are used to open the tab content */
  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
  }
  
  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color: #ddd;
  }
  
  /* Create an active/current tablink class */
  .tab button.active {
    background-color: #ccc;
  }
  
  /* Style the tab content */
  .tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
  }