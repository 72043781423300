.adduser-modal {
    color: black;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .adduser-content {
        z-index: 99;
        width: 500px;
        border-radius: 15px;
        height: auto;
        background-color: rgb(255, 255, 255);
        color: black;
        .selectDropDown {
            max-height: 25%;
            
        }
        .adduser-header {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            background-color: hsl(168deg 87% 35%);
            width: 100%;
            color: white;
        }
        .adduser-body {
            .centirati {
                margin: 0 auto;
            }
            .InputtAdd-user {
                display: flex;
                margin: auto;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border-radius: 0.375rem;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }
            .password-input {
                display: block;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border-radius: 0.375rem;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }
            .generatePass {
                left: 0;
            }
        }

        .x-btn {
            margin-right: 3%;
            cursor: pointer;
        }

        .adduser-btn {
            background-color: hsl(168deg 87% 35%);
            color: white;
            margin: auto;
            &:hover {
                color: white;
            }
        }
    }
}
