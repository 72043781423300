.copy-modal {
    color: black;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .copy-modal-content {
        width: 550px;
        height: 400px;
        background-color: rgb(255, 255, 255);
        border-radius: 12px;
        color: black;
        z-index: 99999;

        
        .widget-view-area{
            background-color: #e9ecef;
        }
        .copy-modal-header {
            padding-bottom: 1%;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            padding-top: 1px;
            margin-bottom: 5%;
            background-color: hsl(168deg 87% 35%);
            width: 100%;
            color: white;
            justify-content: left;
            display: flex;
            .iksiHeader{
                margin-right: 10px;
            }
        }

    }
    
}